import store from '@/store'
import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import IProductEndDate from './models/IProductEndDate'

export interface IProductEndDateStoreState extends Partial<ODataState<IProductEndDate>> {
  year: number,
  supportPolicy: string
  enteringExtendedSupport: IProductEndDate[]
}

store.registerModule('ProductEndDates', ODataStore.extend<IProductEndDateStoreState>({
  namespaced: true,
  state: {
    year: new Date().getFullYear(),
    supportPolicy: 'All',
    enteringExtendedSupport: []
  },
  getters: {
    results(state) {
      return state.result.value
    },
    resultsFiltered(state) {
      return state.result.value
        .filter(x => new Date(x.endDate).getFullYear() === state.year)
        .filter(x => state.supportPolicy === 'All' || x.supportPolicy === state.supportPolicy)
    },
    year(state) {
      return state.year
    },
    years(state) {
      return state.result.value.map(x => {
        return new Date(x.endDate).getFullYear()
      }).sort()
    },
    supportPolicies(state) {
      const supportPolicies = state.result.value
        .filter(x => new Date(x.endDate).getFullYear() === state.year)
        .map(x => {
          return x.supportPolicy
        }).sort()

      return [
        'All',
        ...supportPolicies
      ]
    },
    supportPolicy(state) {
      return state.supportPolicy
    },
    enteringExtendedSupport(state) {
      return state.enteringExtendedSupport
    }
  },
  actions: {
    async load({ dispatch }, parms) : Promise<IProductEndDate[]> {
      const request: ODataRequest = {
        url: 'products/endDates',
        params: {
          $orderby: 'endDate,listingName'
        }
      }
      if (parms) {
        if (!isNaN(parms.tpid) && parms.orgId) {
          request.url += `(tpid=${parms.tpid},organizationId=${parms.orgId})`
        } else if (!isNaN(parms.tpid)) {
          request.url += `(tpid=${parms.tpid})`
        }
      }
      const result = await dispatch('get', request)
      return result.value // commit('setResult', result)
    },
    async loadEnteringExtendedSupport({ dispatch }, parms) : Promise<IProductEndDate[]> {
      const request: ODataRequest = {
        url: 'products/enteringExtendedSupport',
        params: {
          $orderby: 'endDate,listingName'
        }
      }
      if (parms) {
        if (!isNaN(parms.tpid) && parms.orgId) {
          request.url += `(tpid=${parms.tpid},organizationId=${parms.orgId})`
        } else if (!isNaN(parms.tpid)) {
          request.url += `(tpid=${parms.tpid})`
        }
      }
      const result = await dispatch('get', request)
      // commit('setEnteringExtendedSupport', result.value)
      return result.value // commit('setEnteringExtendedSupport', result)
    },
    async exportTimeline({ dispatch }, params: any) {
      const request: ODataRequest = {
        url: 'products/exportTimeline',
        responseType: 'blob',
        noLoading: true,
        headers: {
          Accept: params.format
        }
      }
      if (params) {
        const locale = params.locale
        const tpid = params.tpid
        const orgIds = params.orgIds
        if (locale && !isNaN(tpid) && orgIds) {
          request.url += `(locale='${locale}',tpid=${tpid},organizationIds=[${orgIds}])`
        } else if (locale && !isNaN(tpid)) {
          request.url += `(locale='${locale}',tpid=${tpid})`
        } else if (locale) {
          request.url += `(locale='${locale}')`
        }
      }
      const response = await dispatch('get', request)
      const blob = new Blob([response], {
        type: response.type
      })
      const downloadUrl = window.URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.href = downloadUrl
      let documentName = 'EOSInsights_Timeline'
      if (!isNaN(params.tpid)) {
        documentName += `_${params.tpid}`
      }

      if (params.format === 'text/html') {
        documentName += '.html'
      } else if (params.format === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
        documentName += '.pptx'
      } else if (params.format === 'application/pdf') {
        documentName += '.pdf'
      }

      link.setAttribute('download', documentName)

      document.body.appendChild(link)

      link.click()
      link.remove()
    },
    changeYear({ commit, state, getters }, year: number) {
      commit('setYear', year)
      const supportPolicies = getters.supportPolicies
      if (supportPolicies.indexOf(state.supportPolicy) === -1) {
        commit('setSupportPolicy', 'All')
      }
    },
    changeSupportPolicy({ commit }, supportPolicy: string) {
      commit('setSupportPolicy', supportPolicy)
    }
  },
  mutations: {
    setYear(state, year: number) {
      state.year = year
    },
    setSupportPolicy(state, supportPolicy: string) {
      state.supportPolicy = supportPolicy
    },
    setEnteringExtendedSupport(state, data: IProductEndDate[]) {
      state.enteringExtendedSupport = data
    }
  }
}))
