import router from '@/router'

router.addRoutes([
  {
    path: '/CustomerLookup',
    name: 'CustomerLookup',
    meta: {
      title: 'Customer Lookup'
    },
    components: {
      default: () => import(/* webpackChunkName: "Customers" */ './views/CustomerSearch.vue')
    }
  },
  {
    path: '/CustomerLookup/:tpid',
    name: 'CustomerLookupWithTPID',
    meta: {
      title: 'Customer Lookup'
    },
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/CustomerLookup', query: { tpid: to.params.tpid } }
    }
  },
  {
    path: '/CustomerDetails',
    name: 'CustomerDetails',
    meta: {
      title: 'Customer Details'
    },
    components: {
      default: () => import(/* webpackChunkName: "Customers" */ './views/CustomerFilter.vue')
    }
  },
  {
    path: '/Customers',
    name: 'Customers',
    meta: {
      title: 'Customers'
    },
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/CustomerLookup', query: { tpid: to.query.tpid } }
    }
  },
  {
    path: '/Customers/:tpid',
    name: 'Customer',
    meta: {
      title: 'Customer'
    },
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/CustomerLookup', query: { tpid: to.params.tpid } }
    }
  }
])
