<template>
  <div>
    <v-footer app absolute>
      <v-col class="text-right body-2">&copy;{{ year }} Copyright Microsoft</v-col>
      <v-col class="text-right body-2"><a href="http://go.microsoft.com/fwlink/?LinkId=518021" target="blank">Data Privacy Notice</a> | Send questions to <a href="mailto:mslpol@microsoft.com">mslpol@microsoft.com</a> | <a href="https://forms.office.com/r/i5DVxUwMVG" target="_blank">Please give feedback</a></v-col>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  components: {
  },
  computed: {
    year() {
      return new Date().getFullYear()
    }
  },
  methods: {
    showFeedbackForm() {
      this.$store.dispatch('UserFeedback/showDialog')
    }
  },
  watch: {
  }
}
</script>
