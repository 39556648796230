import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { PluginObject } from 'vue/types/umd'

export interface IApplicationInsightsOptions {
  id: string
}

const plugin: PluginObject<IApplicationInsightsOptions> = {
  install(_Vue, options) {
    // if (process.env.NODE_ENV !== 'production') {
    //   return
    // }
    const { id } = options

    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: id,
        enableAutoRouteTracking: true
      }
    })
    appInsights.loadAppInsights()
    const telemetryInitializer = (envelope) => {
      const match = window.location.search.match('referer=(?<referer>[A-Za-z0-9]*)')
      if (match.length > 1) {
        envelope.data.referer = match[1]
      }
    }
    appInsights.addTelemetryInitializer(telemetryInitializer)
  }
}

export default plugin
