import store from '@/store'
import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import IUser from './models/IUser'

export interface IUserStoreState extends Partial<ODataState<IUser>> {
  photo: string
}

store.registerModule('User', ODataStore.extend<IUserStoreState>({
  namespaced: true,
  state: {
    photo: null
  },
  getters: {
    photo(state) {
      return state.photo
    }
  },
  mutations: {
    setPhoto(state, photo: string) {
      state.photo = photo
    }
  },
  actions: {
    async loadPhoto({ commit, dispatch }) {
      const request: ODataRequest = {
        url: 'Users/Photo'
      }
      const response = await dispatch('get', request)
      commit('setPhoto', response.value)
    }
  }
}))
