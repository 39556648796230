<template>
  <v-app id="app">
    <Loading v-if="loading || !isAuthenticated" />
    <template v-else>
      <AppHeader :disabled="unauthorized" />
      <EnvironmentAlert v-if="showEnvironmentAlert" style="position: fixed; top: 64px; z-index: 99; width: 100%"/>
      <v-main>
        <Unauthorized v-if="unauthorized" />
        <router-view v-else :key="$route.params.id" :style="viewStyle" />
      </v-main>
      <AppUserFeedback />
      <AppAlert />
      <AppFooter />
    </template>
    <WelcomeDialog v-model="showWelcomeDialog" />
  </v-app>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'
import AppAlert from '@/components/AppAlert'
import AppUserFeedback from '@/components/AppUserFeedback'
import EnvironmentAlert from '@/components/EnvironmentAlert'
import WelcomeDialog from '@/areas/welcome/components/WelcomeDialog'
import Unauthorized from '@/views/Unauthorized.vue'

export default {
  components: {
    AppHeader,
    AppFooter,
    AppAlert,
    AppUserFeedback,
    EnvironmentAlert,
    WelcomeDialog,
    Unauthorized
  },
  data() {
    return {
      loading: true,
      showWelcomeDialog: false,
      unauthorized: false
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['Auth/accessToken']
    },
    showEnvironmentAlert() {
      return this.$store.getters['Config/showEnvironmentAlert']
    },
    viewStyle() {
      if (this.showEnvironmentAlert) {
        return 'padding-top: 74px;'
      }
      return ''
    }
  },
  async created() {
    const language = window.navigator.userLanguage || window.navigator.language
    try {
      if (!(await this.$store.dispatch('Auth/login'))) {
        return
      }
      await Promise.all([
        // load global stores
        this.$store.dispatch('Templates/load', language),
        this.$store.dispatch('SupportPolicies/load'),
        this.$store.dispatch('DateFormats/load', language),
        this.$store.dispatch('Customers/checkAccess'),
        this.$store.dispatch('Customers/checkFavorites'),
        this.$store.dispatch('Customers/checkAssignments'),
        this.$store.dispatch('User/loadPhoto')
      ]).catch((x) => {
        this.unauthorized = x.message.endsWith('403')
      }).then(() => {
        if (this.$route.query.path) {
          let slash = '/'
          if (this.$route.query.path.startsWith('/')) {
            slash = ''
          }
          window.location.replace(window.location.origin + slash + this.$route.query.path)
        }
        // moving back to home tab, removing welcome dialog
        // if (!this.$store.getters['Welcome/shown']) {
        //   this.showWelcomeDialog = true
        //   this.$store.dispatch('Welcome/setShown')
        // }
      })
    } finally {
      this.loading = false
      this.$store.dispatch('UserFeedback/incrementVisits')
    }
  }
}
</script>
